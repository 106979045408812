<template>
  <v-input class="html-field" :error-messages="errorMessages" hide-details="auto">
    <tiptap-vuetify
      :value="value"
      class="html-field__content"
      :class="{ 'html-field__content--has-errors': errorMessages.length }"
      :card-props="{ outlined: true }"
      :extensions="extensions"
      :placeholder="placeholder"
      @blur="$emit('blur')"
      @input="$event === '<p></p>' ? $emit('input', '') : $emit('input', $event)"
    />
  </v-input>
</template>

<script>
import { TiptapVuetify, Bold, Italic, BulletList, OrderedList, ListItem, History, Link } from 'tiptap-vuetify'

export default {
  name: 'HtmlField',
  components: {
    TiptapVuetify,
  },
  props: {
    value: { type: String, default: null },
    placeholder: { type: String, default: null },
    errorMessages: { type: String, default: '' },
  },
  data: () => ({
    extensions: [
      History,
      Bold,
      Italic,
      BulletList,
      OrderedList,
      ListItem,
      Link,
    ],
  }),
}
</script>

<style lang="scss" scoped>
.html-field {
  &__content {
    width: 100%;

    &--has-errors {
      border: 2px solid #ff5252;
    }
  }
}

.v-input {
  display: flex;
  flex-direction: column;
  height: auto;
}

::v-deep .v-input__control {
  flex-wrap: nowrap;
}

::v-deep .tiptap-vuetify-editor__content {
  max-height: 200px;
}
</style>
